<script setup>
import { ref, reactive, watch, computed, defineProps, inject } from "vue";

const {
  pageLimit,
  categoryList,
  curCategoryIndex,
  curCategoryId,
  curPage,
  curCategory,
  curArticleList,
  curArticleIndex,
  curArticle,
  likeCount,
  changeCurCategory,
  changeCurArticle,
  openDetail,
  setShare,
  playerStatus,
  playerDriving,
  playerPause,
  playerPlay,
  triggerPlayerStatus,
  fetchAndPlay,
} = inject("useGlobalHook");

const props = defineProps({
  index: Number,
});

// 初始卡片数据
const data = computed({
  get: () => {
    return categoryList[props.index]["articleList"];
  },
  set: (val) => {
    categoryList[props.index]["articleList"][currentIndex.value]["touching"] = val;
  },
});
// 初始卡片下标
const currentIndex = computed({
  get: () => {
    return categoryList[props.index]["articleIndex"]||0;
  },
  set: (val) => {
    categoryList[props.index]["articleIndex"] = val;
  },
});
const flycardVisable = computed(() => (index) => {
  if( index == currentIndex.value - 1 ){
    return true;
  }
  return index >= currentIndex.value && index < currentIndex.value + 3;
});
const flycardLoading = computed(() => () => {
  let diffIndex = Math.abs(props.index - curCategoryIndex.value);
  // console.log("flycardLoading, diffIndex:",diffIndex, " data.length:", data.value.length);
  if( diffIndex!==0 && diffIndex!==1 && diffIndex!==categoryList.length-1 ){
    return true;
  }
  return !data.value.length;
});

// 滑动相关参数
const startX = ref(0); // touchstart的横坐标
const endX = ref(0); // touchend的横坐标
const moveX = ref(0); // touchmove过程的横坐标
const disX = ref(0); // touch划过的横向总距离
const slideDirectionX = ref(0); // 横向滑动的方向
const startY = ref(0); // touchstart的纵坐标
const endY = ref(0); // touchend的纵坐标
const moveY = ref(0); // touchmove过程的纵坐标
const disY = ref(0); // touch划过的纵向总距离
const slideDirectionY = ref(0); // 纵向滑动的方向
const slideFinishDistanceY = ref(300); // 滑动触发动画要求距离
const flycardIsClicked = ref(false); // 卡片是否被点击了 区分touchstart与click
const flycardIsTouching = ref(false); // 卡片是否按住未取消
let slideUpStyle = reactive({
  transition: "none",
}); // 上滑完毕样式
let slideDownStyle = reactive({
  transition: "none",
}); // 下滑完毕样式

let lines = computed(() => {
  let bh = document.body.offsetHeight;
  if( bh <= 640 ){
    return "2";
  }else if( bh <= 667 ){
    return "3";
  }else if( bh <= 736 ){
    return "4";
  }
  return "5";
});
let titleLines = computed(() => {
  let bw = document.body.offsetWidth;
  let bh = document.body.offsetHeight;
  if( bh <= 625 || outlineVisible.value==="hidden" ){
    return "2";
  }else if( bh <= 736 ){
    return "3";
  }
  return "4";
});
let outlineVisible = computed(() => {
  let bw = document.body.offsetWidth;
  let bh = document.body.offsetHeight;
  if(bh < 590){
    return "hidden";
  }
  if( navigator.userAgent.match(/iPhone/gi) && bh<=667 ){
    return "hidden";
  }
  let cardH = bh - fixHeight.value;
  let cardImgH = 0.48 * bw;
  let cardBtnH = 93/750 * bw;
  let cardTitleH = (36+28+40*3)/750 * bw;
  if( cardH-cardImgH-cardBtnH <= cardTitleH ){
    return "hidden";
  }
  return  "visible";
});

// 重置活跃组 即当前卡片、上一张、下两张共四张卡片
const resetGroup = () => {
  let group = [currentIndex.value - 1, currentIndex.value, currentIndex.value + 1];
  for (let i = 0; i < group.length; i++) {
    if (group[i] >= 0 && !!data.value[group[i]]) {
      resetStyle();
    }
  }
};
// 重置样式
const resetStyle = () => {
  slideUpStyle["transition"] = "transform ease .2s";
  slideUpStyle["transform"] = "scale(1)";
  slideUpStyle["z-index"] = 9;
  slideUpStyle["opacity"] = 1;
  slideDownStyle["transition"] = "transform ease .2s";
  slideDownStyle["transition"] = "none";
  slideDownStyle["transform"] = "scale(1)";
  slideDownStyle["z-index"] = 9;
  slideDownStyle["opacity"] = 1;
  setTimeout(() => {
    slideUpStyle["transition"] = "none";
  }, 200);
};

const slideUp = () => {
  if (currentIndex.value === data.value.length - 1) {
    // 最后一张卡片
  } else {
    console.log("slideUp",currentIndex.value);
    slideUpStyle["transition"] = "transform ease .2s";
    slideUpStyle["transform"] = "translateY(-100vh)";
    data.value[currentIndex.value]["touching"] = false;
    currentIndex.value++;
    setTimeout(() => {
      resetGroup();
    }, 200);
    //
    fetchAndPlay("cardEffect")
  }
  if (currentIndex.value > data.value.length) currentIndex.value = data.value.length - 1;
};

const slideDown = () => {
  console.log("slideDown", currentIndex.value);
  setTimeout(() => {
    resetGroup();
  }, 200);
  data.value[currentIndex.value]["touching"] = false;
  currentIndex.value--;
  if (currentIndex.value < 0) currentIndex.value = 0;
  //
  fetchAndPlay("cardEffect")
};

const onTouchstart = (e) => {
  flycardIsClicked.value = true;
  flycardIsTouching.value = true;
  startY.value = e.touches[0].clientY;
  startX.value = e.touches[0].clientX;
};
const onTouchmove = (e) => {
  flycardIsClicked.value = false;
  flycardIsTouching.value = true;
  moveY.value = e.touches[0].clientY;
  moveX.value = e.touches[0].clientX;
  disY.value = startY.value - moveY.value;
  disX.value = startX.value - moveX.value;

  if (Math.abs(disY.value) > Math.abs(disX.value)) {
    // 纵向滑动

    if (
      disY.value > 0 &&
      !!data.value[currentIndex.value] &&
      !!data.value[currentIndex.value + 1]
    ) {
      //  手指向上划
      data.value[currentIndex.value]["touching"] = true;
      slideDirectionY.value = 1;
      slideUpStyle["transform"] = `translateY(${-disY.value}px) scale(1)`;
      slideUpStyle["opacity"] = 1 - disY.value / document.body.offsetHeight;
    } else if (disY.value < 0 && !!data.value[currentIndex.value - 1]) {
      //  手指向下划
      data.value[currentIndex.value - 1]["touching"] = true;
      slideDirectionY.value = 0;
      slideDownStyle["transition"] = "none";
      slideDownStyle["z-index"] = 11;

      slideDownStyle["opacity"] = -disY.value / document.body.offsetHeight + 0.2;
      if (Math.abs(disY.value) <= slideFinishDistanceY.value) {
        // 未超过翻页距离
        slideDownStyle["transform"] = `translateY(${
          -window.innerHeight*0.8 - disY.value
        }px) scale(${(-0.2 / slideFinishDistanceY.value) * disY.value + 0.8})`;
      } else {
        // 超过翻页距离
        slideDownStyle["transform"] = `translateY(${-window.innerHeight*0.8 - disY.value}px)`;
      }
    }
  }
};
const onTouchend = (e) => {
  if (!flycardIsClicked.value) {
    endY.value = e.changedTouches[0].clientY;
    endX.value = e.changedTouches[0].clientX;
    disY.value = startY.value - endY.value;
    disX.value = startX.value - endX.value;

    if (Math.abs(disY.value) > Math.abs(disX.value)) {
      //  curArticleIndex.value++
      if (Math.abs(disY.value) < 0.3 * slideFinishDistanceY.value) {
        // 达不到翻页的距离
        resetGroup();
      } else {
        // 触发滑动后停止行车模式
        playerStatus.value = 0;
        playerDriving.value = false;
        // 达到翻页距离了
        slideUpStyle["transition"] = "transform ease .2s";
        if (slideDirectionY.value) {
          slideUp();
        } else {
          slideDown();
        }
      }
    }
  }
  flycardIsTouching.value = false;
};
const onTouchCancel = (e) => {
  flycardIsTouching.value = false;
};

// console.log("card-init",currentIndex.value, data.value, props.index, categoryList[0]["articleList"]);

watch(playerDriving, (newVal, oldVal) => {
    console.log("watch playerDriving:", newVal," curCategoryIndex:",curCategoryIndex.value," props.index:", props.index)
    if(newVal && props.index===curCategoryIndex.value){
      console.log("watch playerDriving, slideUp...")
      slideUp()
      playerDriving.value = false;
    }
})


let fixHeight = computed(()=>{
  let bw = document.body.offsetWidth;
  let topHeight = bw*(1/3*(53/190) + 42/750);
  if( navigator.userAgent.match(/Android|iPhone|Mobile/gi) ){
    // topHeight = 0;
  }
  let navHeight = bw*104/750;//.swiper-slide的padding-top
  let tabHeight = bw*100/750 + 22.5;//.tabbar的height和bottom
  let fixHeight = topHeight+navHeight+tabHeight+40;//附加阴影高
  let isInNeedToFixBrowser = inject("UA");
  if (isInNeedToFixBrowser) {
    fixHeight += 44;//特殊浏览器底部位移
  }
  // console.log("topHeight:", topHeight,"tabHeight:", tabHeight,"fixHeight:", fixHeight)
  return fixHeight;
});


let cardHeight = ref(document.body.offsetHeight - fixHeight.value + "px");
// console.log("fixHeight:", fixHeight.value, "cardHeight:", cardHeight.value)


const likeAniActive = ref(false)
const pressLike = async () => {
  console.log('pressLike');
  likeAniActive.value = false;
  let api = "/api/countCardHits";
    // process.env.NODE_ENV === "production" ? "/api/countCardHits" : "/likeApi";
  fetch(api + "/" + curArticle.value["cid"] + "/" + curArticle.value["nid"] + "/", {
    method: "get",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      console.log("Tabbar pressLike, res:", res)
      if (res.result === "success") {
        // likeCount.value = res.data;
        likeCount.value++;
        curArticle.value["hits"] = likeCount.value;
        likeAniActive.value = true;
        setTimeout(() => {
          likeAniActive.value = false;
        }, 1000);
      }
    });
};
</script>

<template>
  <div :class="[index == curCategoryIndex ? 'card-wrapper active' : 'card-wrapper']" >
    <div class="card-loading" v-if="flycardLoading()"></div>
    <div class="card-item-container" :key="item.nid" v-for="(item, index) in data">
      <div
        class="card-item"
        :class="[
          `${flycardVisable(index) ? 'card-item--active' : ''}`,
          `card-item--active${index - currentIndex}`,
        ]"
        v-if="flycardVisable(index) && !flycardLoading()"
        
        @touchstart="onTouchstart"
        @touchmove="onTouchmove"
        @touchend="onTouchend"
        @touchcancel="onTouchCancel"
        :style="
          (index == currentIndex && item.touching && slideUpStyle) ||
          (index == currentIndex - 1 && item.touching && slideDownStyle)
        "
      >
        <div
          class="card-item-top"
          :class="[
            item.video||item.videoPoster ? 'card-item-video' : '',
            //item.type === 3 ? 'fullscreen' : '',
          ]"
        >
          <van-image class="card-item-poster" fit="cover" :src="item.img1 + ''"
            v-if="item.video==''&&item.img2==''&&item.img1!=''" >
            <template v-slot:loading> <van-loading type="spinner" size="20" /> </template>
          </van-image>

          <van-swipe class="card-item-swipe" :autoplay="3000" :duration="1000" indicator-color="#fc2133" 
            :stop-propagation="false" :touchable="false" 
            v-if="item.video==''&&item.img2!=''"  >
            <van-swipe-item v-if="item.img1!=''" >
              <van-image class="card-item-poster" fit="cover" :src="item.img1 + ''" >
                <template v-slot:loading> <van-loading type="spinner" size="20" /> </template>
              </van-image>
            </van-swipe-item>
            <van-swipe-item v-if="item.img2!=''" >
              <van-image class="card-item-poster" fit="cover" :src="item.img2 + ''" >
                <template v-slot:loading> <van-loading type="spinner" size="20" /> </template>
              </van-image>
            </van-swipe-item>
            <van-swipe-item v-if="item.img3!=''" >
              <van-image class="card-item-poster" fit="cover" :src="item.img3 + ''" >
                <template v-slot:loading> <van-loading type="spinner" size="20" /> </template>
              </van-image>
            </van-swipe-item>
          </van-swipe>
          
          <video
            controls
            playsinline
            webkit-playsinline
            x5-video-player-type="true"
            x5-video-orientation="portraint"
            class="card-item-poster"
            :poster="item.img1"
            :src="item.video"
            v-if="item.video"
            @play="triggerPlayerStatus(0)"
          ></video>
          
        </div>

        <div class="card-item-title">{{ item.title }}</div>
        <div class="card-item-outline" v-show="item.abstract||item.type != 3">{{ item.abstract||item.outline }}</div>

        <div class="card-item-tip" @click="slideUp">
          <img
            src="../assets/swipe.gif"
          />
          <!-- <span>上滑看下条</span> -->
        </div>
        <div class="card-item-more" @click.prevent="
          openDetail($event, {
            url: item.url,
            nid: item.nid,
            cid: curCategoryId,
          })
        ">查看详情</div>
        <div class="car-item-like" @click="pressLike">
          <img class="likeImg" v-show="item.hits <= 0" src="../assets/card_btn_love.png" />
          <img class="likeImg" v-show="item.hits > 0" src="../assets/card_btn_love_pre.png" />
          <!-- <img class="likeImg" src="../assets/card_btn_love_pre.png" /> -->
          <!-- <p class="like-ani" :class="likeAniActive?'active':''">+1</p> -->
          <div class="likeCount" v-show="item.hits > 0">{{ item.hits }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-wrapper {
  width: 95%;
  // height: calc(100vh - 452px);
  height: v-bind(cardHeight);
  min-height: 560px;
  position: relative;
  transform: scale(0.95);
  transition: all 0.3s ease;

  &.active {
    transform: scale(1);
  }

  .card-item-container {
    width: 100%;
    height: 100%;
    position: absolute;
    .card-item {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
      background-color: #fff;
      margin: 0 auto;
      border-radius: 20px;
      transform: translateY(14%) scale(0.84);
      transition: transform 0.3s ease;
      opacity: 0;
      text-align: justify;
      cursor: grab;

      .card-item-top {
        background: url("../assets/skeleton.png") no-repeat center;
        background-size: contain;

        .card-item-poster {
          width: 100%;
          height: 360px;
          object-fit: cover;
          vertical-align: middle;
        }
      }
      .card-item-video {
        position: relative;
        &.fullscreen {
          height: calc(100% - 304px);
          background: #000;
          .card-item-poster {
            object-fit: contain;
            height: 100%;
          }
        }
        .card-video-pause {
          position: absolute;
          left: 250px;
          width: 100px;
          height: 100px;
          top: 120px;
          background: rgba($color: #000000, $alpha: 0.6);
          border-radius: 50%;
          padding: 24px;
        }
      }
      .card-item-title {
        @include line(v-bind(titleLines));
        margin: 36px 36px 28px;
        font-weight: bold;
        font-size: 40px;
        color: #333333;
      }
      .card-item-outline {
        @include line(v-bind(lines));
        margin: 0 40px;
        font-size: 30px;
        visibility: v-bind(outlineVisible);
      }

      @keyframes downup {
        0% {
          transform: translateY(-4px);
        }

        100% {
          transform: translateY(4px);
        }
      }

      .card-item-search {
        display: flex;
        align-items: center;
        position: absolute;
        left: 36px;
        bottom: 38px;
        width: 180px;
        height: min-content;
        img {
          vertical-align: baseline;
        }
      }
      .card-item-tip {
        position: absolute;
        // left: 24px;
        // bottom: 32px;
        left: calc(50% - 40px);
        bottom: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #999;
        font-size: 28px;
        animation: downup 1.3s ease-in-out 0.5s infinite alternate none;

        img {
          width: 80px;
          // height: 32px;
          object-fit: contain;
          margin-right: 16px;
        }
      }

      .card-item-more {
        position: absolute;
        // right: 24px;
        // bottom: 32px;
        right: 53px;
        bottom: 43px;
        font-size: 28px;
        color: #fd2334;
        cursor: pointer;
      }

      .car-item-like {
        position: absolute;
        left: 43px;
        bottom: 13px;
        font-size: 28px;
        color: #fd2334;
        cursor: pointer;
        
        .likeImg {
          width: 80px!important;
          height: 80px!important;
        }
        .likeCount {
          background-color: #f82635;
          color: #fff;
          position: absolute;
          right: -30px;
          top: 0px;
          font-size: 24px;
          width: 60px;
          height: 28px;
          line-height: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          border-radius: 14px;
        }
        .like-ani {
          position: absolute;
          width: 100%;
          height: min-content;
          text-align: center;
          color: #f82635;
          font-size: 24px;
          top: 0;
          left: 0;
          opacity: 0;
          &.active {
            animation: likeAni ease 10s forwards;
          }
        }
      }

      &.card-item--active {
        opacity: 1;
        box-shadow: 0 5px 23px 11px rgba($color: rgb(0, 0, 0), $alpha: 0.13);
      }
      &.card-item--active-1 {
        opacity: 0;
        box-shadow: none;
      }
      &.card-item--active0 {
        z-index: 9;
        transform: translateZ(0) scale(1);
      }
      &.card-item--active1 {
        z-index: 8;
        opacity: 0.9;
        // transform: translateY(60px) scale(.92);
        transform: translateY(6%) scale(0.92);
      }
      &.card-item--active2 {
        z-index: 7;
        opacity: 0.8;
        // transform: translateY(120px) scale(.84);
        transform: translateY(12%) scale(0.84);
      }
    }
  }
}

.card-loading {
  background: url("../assets/card_bg.png") no-repeat center / 80%;
  width: 100%;
  height: calc(100vh - 460px);
  overflow: hidden;
  position: absolute;
  background-color: #fff;
  margin: 0 auto;
  border-radius: 2.666667vw;
  transform: translateZ(0) scale(1);
  opacity: 1;
  text-align: justify;
  top: 0;
  z-index: 999;
}
</style>
