<script setup>
import { ref, reactive, inject, computed } from "vue";

const { categoryList, curCategoryIndex, changeCurCategory, fetchAndPlay } = inject("useGlobalHook");

// import FreeMode module if you use core version of Swiper
// import SwiperCore, { Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/swiper.scss";

// SwiperCore.use([Thumbs]);

const thumbsSwiper = ref(null);
const setThumbsSwiper = (swiper) => {
  thumbsSwiper.value = swiper;
};

let swiperInstance = reactive({});
let thumbsSwiperInstance = reactive({});

const onSlideChange = (swiper) => {
  console.log("onSlideChange realIndex:", swiper.realIndex, "swipeDirection:", swiper.swipeDirection);
  let direction = swiper.swipeDirection;
  if (direction === "next") {
    thumbsSwiperInstance.slideNext();
    changeCurCategory(1);
    fetchAndPlay("swiperEffect")
  } else if (direction === "prev") {
    thumbsSwiperInstance.slidePrev();
    changeCurCategory(0);
    fetchAndPlay("swiperEffect")
  }
};

const onTransitionEnd = (swiper) => {
  console.log("onTransitionEnd ", swiper.realIndex, curCategoryIndex.value);
  if (swiper.realIndex < categoryList.length && swiper.realIndex != curCategoryIndex) {
    thumbsSwiperInstance.slideToLoop(swiper.realIndex, 0);
    curCategoryIndex.value = swiper.realIndex;
  }
};

const onSlideInit = (swiper) => {
  swiperInstance = swiper;
};
const onthumbsSlideInit = (swiper) => {
  thumbsSwiperInstance = swiper;
};
const onSlideClick = (index) => {
  let diff = index - thumbsSwiperInstance.realIndex;
  console.log(diff);
  if (diff === 1 || diff === -4) {
    slideNext();
  } else if (diff === -1 || diff === 4) {
    slidePrev();
  } else if (diff === 3 || diff === -2) {
    slidePrev();
    slidePrev();
  } else if (diff === -3 || diff === 2) {
    slideNext();
    slideNext();
  }
};

const slidePrev = () => {
  thumbsSwiperInstance.slidePrev(300, false);
  swiperInstance.slidePrev(300, false);
  changeCurCategory(0);
};
const slideNext = () => {
  thumbsSwiperInstance.slideNext(300, false);
  swiperInstance.slideNext(300, false);
  changeCurCategory(1);
};

const slideTo = (index) => {
  console.log(index);

  // curCategoryIndex.value = index
  // thumbsSwiperInstance.slideToLoop(index, 300, false)
  // swiperInstance.slideToLoop(index, 300, false)
};

const toHome = () => {
  if (window.__wxjs_environment) {
    wx.miniProgram.navigateTo({ url: "/pages/index/index" });
  } else {
    window.location.href = "http://m.lyd.com.cn/";
  }
};

import Flycard from "../components/Flycard.vue";
import Tabbar from "../components/Tabbar.vue";





let isFirstIn = ref(localStorage.getItem("isFirstInFlyCard"));
const guideClose = () => {
  localStorage.setItem("isFirstInFlyCard", true);
  isFirstIn.value = true;
};
</script>

<template>
  <div class="guide-mask" v-if="!isFirstIn" @click="guideClose">
    <img src="../assets/guide-horizontal.png" alt="" />
    <img src="../assets/guide-vertical.png" alt="" />
    <img src="../assets/guide-btn.png" />
  </div>
  <div class="page">
    <div class="logo" @click="toHome" >
      <img src="../assets/card_logo.png" />
      <span>国家重点新闻网</span>
    </div>
    <div>
      <div class="thumbs-swiper-container">
        <swiper
          :loop="true"
          @init="onthumbsSlideInit"
          :allowTouchMove="false"
          :watchSlidesProgress="true"
          :slidesPerView="5"
          :centered-slides="true"
          class="thumbs-swiper"
        >
          <swiper-slide
            v-for="(item, index) in categoryList"
            :key="index"
            @click="slideTo(index)"
          >
            <div class="thumbs-item">
              <p class="thumbs-text">{{ item.text }}</p>
              <div class="thumbs-line"></div>
            </div>
            
          </swiper-slide>
        </swiper>
      </div>
      <div class="cards-swiper-container">
        <swiper
          :loop="true"
          class="mySwiper"
          :centered-slides="true"
          slidesPerView="auto"
          @slideChange="onSlideChange"
          @init="onSlideInit"
          @transition-end="onTransitionEnd"
        >
          <swiper-slide v-for="(item, index) in categoryList" :key="item.nid">
            <Flycard :index="index" />
          </swiper-slide>
        </swiper>
      </div>
    </div>

    <Tabbar />
  </div>
</template>

<style lang="scss">
  .guide-mask {
    width: 100vw;
    height: 100vh;
    max-width: 750px;
    position: fixed;
    z-index: 101;
    background-color: rgba($color: #000000, $alpha: 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 647px;
      margin-top: 114px;
    }
    img:nth-child(2) {
      margin-top: 146px;
    }
    img:nth-child(3) {
      margin-top: 42px;
      width: 260px;
    }
  }
.page {
  background-color: #f1f2f4;
}

.logo {
  display: flex;
  width: 80%;
  margin: 42px auto 0;
  position: relative;
  z-index: 99;
  align-items: center;
  justify-content: flex-start;

  img{
    width: 250px;
    margin-left: 20px;
  }
  span{
    color: #000;
    margin-left: 20px;
  }
}
.thumbs-swiper-container {
  width: 640px;
  margin: 0 auto;
  position: absolute;
  left: 55px;
  top: 141px;
  color: #000;
  // background: cornflowerblue;
  height: 60px !important;
  .thumbs-swiper {
    height: max-content;
    // background: darkcyan;
    height: 60px !important;
    .swiper-slide {
      opacity: 0.8;
      text-align: center;
      font-size: 28px;
      height: max-content;

      .thumbs-logo {
        width: 64px;
        height: 64px;
        object-fit: contain;
        vertical-align: text-top;
      }

      .thumbs-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .thumbs-text{
          display: inline-block;
          height: 40px !important;
          // font-weight: bold;
          // border-bottom: 2px solid #fc2133;
        }
        .thumbs-line {
          height: 6px;
          // width: 30%;
          // background: #fc2133;
          // border-radius: 6px;
        }
      }
      
    }
    .swiper-slide-prev,
    .swiper-slide-next {
      opacity: 1;
    }
    .swiper-slide-active {
      opacity: 1;
      margin: 0 8px;
      transform: scale(calc(32 / 26));
      color: #fc2133;
      // font-size: 30px !important;
      // line-height: 1.3;
      .thumbs-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .thumbs-text{
          display: inline-block;
          font-weight: bold;
          // border-bottom: 2px solid #fc2133;
        }
        .thumbs-line {
          height: 4px;
          width: 30%;
          background: #fc2133;
          border-radius: 4px;
        }
      }
      
    }
  }
}

.cards-swiper-container {
  width: 100%;
  height: 100vh;
  // margin-top: 144px;
  .swiper-container {
    height: 100%;

    .swiper-slide {
      width: 80% !important;
      align-content: center;
      display: flex;
      justify-content: center;
      padding-top: 104px;
    }
  }
}
</style>
