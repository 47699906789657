<script setup>
import { ref, inject, watch, computed } from "vue";
const showShareTip = ref(false);
const showShareImg = ref(false);

const showOpenPcBtn = computed(() => {
  return window.__wxjs_environment === "miniprogram" ? false : true;
});

const toHome = () => {
  if (window.__wxjs_environment) {
    wx.miniProgram.navigateTo({ url: "/pages/home/home" });
  } else {
    window.location.href = "https://m.lyd.com.cn/touch/";
  }
};
const { curArticle, playerStatus, triggerPlayerStatus, likeCount } = inject(
  "useGlobalHook"
);


const likeAniActive = ref(false)
const pressLike = async () => {
  likeAniActive.value = false;
  let api =
    process.env.NODE_ENV === "production" ? "/api/countCardHits" : "/likeApi";
  fetch(api + `/${curArticle.value["cid"]}/${curArticle.value["nid"]}/`, {
    method: "get",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      console.log("Tabbar pressLike, res:", res)
      if (res.result === "success") {
        // likeCount.value = res.data;
        likeCount.value++;
        likeAniActive.value = true;
        setTimeout(() => {
          likeAniActive.value = false;
        }, 1000);
      }
    });
};

const shareImg = computed(() => {
  let shareQR = curArticle.value?curArticle.value['qr_card']:'';
  console.log("shareImg-computed, shareQR:", shareQR)
  return shareQR;
});

let isInNeedToFixBrowser = inject('UA')
let tabbarBottom = ref('22.5px')
if(isInNeedToFixBrowser) {
  tabbarBottom.value = '66.5px'
}
</script>

<template>
  <van-overlay :show="showShareTip" @click="showShareTip = false" z-index="121">
    <div class="wrapper">
      <img src="../assets/sharebg.png" class="sharebg" />
    </div>
  </van-overlay>
  <van-overlay :show="showShareImg" @click="showShareImg = false" z-index="122">
    <div class="wrapper">
      <div class="mask_wrap">
        <img :src="shareImg" style="width: 100%;height: 100%;" >
        <p class="tips" style="margin: 10px auto;color: #fff;">长按上方图片保存或分享</p>
      </div>
    </div>
  </van-overlay>
  <div class="tabbar">
    <!-- <div class="like" @click="pressLike">
      <img v-show="likeCount <= 0" src="../assets/card_btn_love.png" />
      <img v-show="likeCount > 0" src="../assets/card_btn_love_pre.png" />
      <p class="like-ani" :class="likeAniActive?'active':''">+1</p>
      <div class="likeCount" v-show="likeCount > 0">{{ likeCount }}</div>
    </div> -->
    <div class="home" @click="toHome">
      <img src="../assets/card_btn_home.png" />
      <!-- <p>首页</p> -->
    </div>

    <div class="statu">
      <img v-show="playerStatus === 0" src="../assets/driver.png" @click="triggerPlayerStatus(0)"/>
      <img v-show="playerStatus === 2" src="../assets/driverSelected.png" @click="triggerPlayerStatus(2)" />
    </div>

    <div class="share" @click="showShareTip = true">
      <img src="../assets/card_btn_share.png" />
    </div>

    <div class="pc" @click="showShareImg = true">
      <img src="../assets/card_btn_PC.png" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@keyframes likeAni {
  0% {
    font-size: 24px;
    opacity: 1;
    top: 0;
  }
  100% {
    font-size: 32px;
    opacity: 0;
    top: -40px;
  }
}

.tabbar {
  width: 100%;
  height: 100px;
  // background-color: #fff;
  color: #a1a1a1;
  position: absolute;
  bottom: v-bind(tabbarBottom);
  left: 0;
  z-index: 99;
  // border-top-left-radius: 48px;
  // border-top-right-radius: 48px;
  font-size: 24px;
  padding: 0 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    width: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    &.status {
      width: 100px;
      height: 100px;
      // margin-top: -60px;
      background: url("../assets/status_bg.png") no-repeat center / contain;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 60px;
      }
    }

    &.cannotplay {
      background: url("../assets/status_fail_bg.png") no-repeat center / contain;
    }
  }

  .like {
    position: relative;
    .likeCount {
      background-color: #f82635;
      color: #fff;
      position: absolute;
      right: -12px;
      top: 0;
      font-size: 24px;
      width: 40px;
      height: 28px;
      line-height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 14px;
    }
    .like-ani {
      position: absolute;
      width: 100%;
      height: min-content;
      text-align: center;
      color: #f82635;
      font-size: 24px;
      top: 0;
      left: 0;
      opacity: 0;
      &.active {
        animation: likeAni ease 1s forwards;
      }
    }
  }
}
.mask_wrap{
		position: absolute;
		top: 85px;
		/*bottom: 55px;*/
		left: 20px;
		right: 20px;
		/*width: 360px;
		height: 480px;
		background-color: #FFFFFF;*/
		margin:  auto;
    text-align: center;
	} 
	.mask_wrap img{
		max-width: 100%;
	}

</style>
